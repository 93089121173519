@media (max-width: 575.98px) {
    .login-section {
        padding: 0;
    }
    .login-section .form-control {
        margin-bottom: 15px;
    }
    .login-section .login-btn {
        margin: 20px auto 0;
    }
    .login-section .btn-primary {
        padding: 8px 34px;
        font-size: 14px;
    }
    .menu-responsive{
        display: block;
    }
    .register-section.login-section fieldset {
        padding: 4px 10px;
        margin-top: 10px;
    }

}
@media (min-width: 576px) and (max-width: 767.98px) {
    .menu-responsive{
        display: block;
    }
    .login-section {
        padding: 0px;
    }
    .register-section.login-section fieldset {
        padding: 4px 10px;
        margin-top: 10px;
    }
}



@media (min-width: 768px) and (max-width: 991.98px) {
    body {
        margin-top: 63px;
    }
    .history-nev ul li a {
        padding: 10px 17px;
        font-size: 12px;
    }
    .fixed-top {
        position: fixed;
    }

    .banner-tab-view .tab-content {
        width: 100%;
    }

    .menu-full .navbar-brand img {
        width: 160px;
    }

    .menu-full .signup-btn {
        font-size: 12px;
        padding: 4px 11px;
        min-width: auto;
    }

    .menu-full ul {
        margin-top: 0;
        margin-left: 0;
    }

    .menu-full .navbar-light .navbar-nav .nav-link {
        font-size: 12px;
        padding: 0 8px;
    }

    .icon-form img {
        width: 100%;
    }

    .option-in .css-1wa3eu0-placeholder {
        font-size: 13px;
    }

    .option-in .react-date-picker__inputGroup {
        font-size: 13px;
    }

    /* body {
        margin-top: 96px;
    } */
    .banner-left-text ul li {
        margin: 6px 0;
        font-size: 12px;
        width: 100%;
    }

    .stats span {
        font-size: 24px;
    }

    .banner-left-text h1 {
        font-size: 28px;
    }

    .container-fluid.co-p {
        padding: 81px 17px 0 17px;
        margin: 0 auto 0;
    }

    .box-count ul li h5 {
        font-size: 12px;
    }

    .option-form {
        margin-top: 20px;
    }
    .resnone{
        display: none;
    }
    .dry-reefer-select ul li a {
        padding: 5px 7px;
        font-size: 11px;
    }
    .box-count ul li {
        margin: 2px;
        padding: 6px 0;
        width: 24%;
    }
    .option-in .react-datepicker__input-container input {
        padding: 9px 6px;
        font-size: 13px;
    }
    .search-btn a {
        padding: 7px 30px;
        font-size: 14px;
    }
    .respopnone {
        display: none;
    }
    .total-charges-newconfirm-box li:nth-child(2) {
        padding: 14px 6px 0 6px;
        font-size: 13px;
    }
    .total-charges-newconfirm-box b {
        font-size: 14px;
    }
    .total-charges-newconfirm-box b span {
        font-size: 12px;
    }
    .total-charges-newconfirm-box p {
        font-size: 10px;
    }
    .confirm-booking-inn-box b {
        font-size: 11px;
    }
    .confirm-booking-inn-box h1 {
        font-size: 15px;
    }
    .confirm-booking-new-table table th:nth-child(1) {
        width: 49%;
    }
    .new-class-tr-div-packing-group .custom-control-inline {
        margin-right: 3px;
        padding-left: 17px;
    }
    .new-class-tr-div-packing-group .custom-control-label {
        font-size: 13px;
    }
    .your-booking-quote-full {
        width: 100%;
    }
    .booking-includes li {
        font-size: 15px;
    }
    .upload-2-files .btn {
        padding: 8px 14px;
        font-size: 11px;
    }
    .menu-responsive{
        display: block;
    }
    .login-section {
        padding: 0;
    }
    .register-section.login-section fieldset {
        padding: 3px 8px;
        margin-left: 11px;
    }
}
@media (min-width: 992px) and (max-width: 1024.98px) {
    .menu-responsive{
        display: block;
    }
    .login-section {
        padding: 0;
    }
    .register-section.login-section fieldset {
        padding: 3px 8px;
        margin-left: 11px;
    }
    .login-section {
        padding: 0;
    }
}
@media (min-width: 1025px) and (max-width: 1199.98px) {
    .fixed-top {
        position: fixed;
    }
    body {
        margin-top: 64px;
    }
    .history-nev ul li a {
        padding: 10px 17px;
        font-size: 12px;
    }
    .banner-tab-view .tab-content {
        width: 100%;
    }

    .menu-full ul {
        margin-left: 0;
    }

    .menu-full .navbar-light .navbar-nav .nav-link {
        padding: 0 17px;
        
    }

    .menu-full .navbar-brand img {
        width: 163px;
    }

    /* body{
        margin-top: 100px;
    } */
    .banner-left-text ul li {
        width: 100%;
        font-size: 14px;
    }

    .banner-left-text h1 {
        font-size: 38px;
    }

    .stats span {
        font-size: 31px;
    }

    .boat-wave-in .plane-body {
        background-size: 198px;
        background-repeat: no-repeat;
    }

    .boat-wave-in .plane {
        top: 8%;
    }

    .container-fluid.co-p {
        padding: 90px 16px 0 16px;
        margin: 0 0 0 0;
    }

    .box-count ul li h5 {
        font-size: 12px;
    }

    .option-form {
        margin-top: 20px;
    }

    .dry-reefer-select {
        margin: 10px auto 0;
    }

    .box-count li .input-group p {
        font-size: 16px;
    }

    .upload-2-files .btn {
        padding: 8px 10px;
        font-size: 11px;
    }
    .total-charges-newconfirm-box p {
        font-size: 10px;
    }
    .total-charges-newconfirm-box li:nth-child(2) {
        padding: 14px 8px 0 8px;
        font-size: 13px;
    }
    .total-charges-newconfirm-box b {
        font-size: 12px;
    }
    .total-charges-newconfirm-box b span {
        font-size: 12px;
    }
    .confirm-booking-inn-box h1 {
        font-size: 15px;
        min-height: 50px;
    }
    .confirm-booking-inn-box b {
        font-size: 11px;
    }
    .confirm-booking-new-table table th:nth-child(1) {
        width: 49%;
    }
    .new-describe-your-box label span {
        font-size: 14px;
    }
    .note-approvals p {
        font-size: 13px;
    }
    .booking-includes li {
        font-size: 14px;
    }
    .booking-includes.booking-excludes li span {
        font-size: 13px;
    }
    .fotter-no-re{
    display: none;
    }
    .user-side-nav.booking-left-address .loading-text {
        font-size: 23px;
    }
    .booking-left-address h6 {
        font-size: 12px;
    }

}


@media (min-width: 1200px) and (max-width: 1438.99px) {
    .banner-tab-view .tab-content {
        width: 100% !important;
    }

    .banner-left-text h1 {
        font-size: 41px;
    }

    .banner-left-text ul li {
        font-size: 11px;
    }

    .routes-found li {
        font-size: 12px;
        padding: 10px 7px;
    }

    .main-listing-right td.shiping-name p {
        font-size: 13px;
    }

    .main-listing-right .table td,
    .main-listing-right .table th {
        padding: 7px 8px;
    }

    .main-listing-right tr.bg-tr-change td li {
        font-size: 9px;
        color: #71757f;
        padding: 0 5px;
    }

    .main-listing-right tr th:nth-child(1) {
        width: 351px;
    }

    .main-listing-right tr th:nth-child(2) {
        width: 81px;
    }

    .main-listing-right tr th:nth-child(3) {
        width: 124px;
    }
    .main-listing-right tr th.changes-new:nth-child(3){
        width: 42px;
    }
    .main-listing-right tr th:nth-child(4) {
        width: 113px;
    }

    .main-listing-right a.price-drop {
        font-size: 11px;
    }

    .main-listing-right td li {
        font-size: 12px;
    }

    .bg-tr-change li p {
        font-size: 10px;
    }

    .routes-found li:nth-child(4) {
        margin-top: -2px;
    }

    .menu-full i {
        font-size: 10px;
    }

    .modify-container-type.ul-in span.qty {
        margin-right: 0;
    }

    .search .react-date-picker__button {
        padding: 4px 3px;
    }

    .menu-full a.login {
        margin-right: 13px;
    }

    .confirm-booking-table table {
        width: 87%;
    }

    .your-booking-quote-full {
        width: 87%;
    }

    .water ul.waves li.wave-one {
        height: 17px;
    }

    .water ul.waves li.wave-two {
        height: 40px;
    }

    .water ul.waves li.wave-three {
        height: 50px;
    }

    .water ul.waves li.wave-four {
        height: 60px;
    }

    .boatwave {
        bottom: -12px;
    }

    .stats span {
        font-size: 22px;
    }

    #counter-stats h5 {
        font-size: 11px;
    }

    .counting {
        font-size: 15px;
    }

    .confirm-booking-table p {
        font-size: 14px;
    }

    .table-booking-list .booking-date-table td {
        font-size: 10px;
    }

    .table-booking-list .booking-date-table td.date-right {
        font-size: 10px;
    }

    .table-booking-list .booking-date-table td.date-right b {
        font-size: 9px;
    }

    td.total b {
        font-size: 12px;
    }

    .table-booking-list table td {
        padding: 6px 6px;
    }

    .confirm-booking-table td.bookoing-right-table {
        padding: 0 !important;
    }

    .table-booking-list .confirm-booking-table td:nth-child(4) b {
        padding-left: 1px;
    }

    .booing-status ul li {
        padding-right: 10px;
    }

    .table-booking-list .table-booking-inn td:nth-child(2) {
        width: auto;
    }

    .table-booking-list .table-booking-inn td.hozara-dous {
        font-size: 14px;
    }

    .table-booking-list .table-booking-inn td.discount {
        font-size: 14px;
    }

    .table-booking-list .table-booking-inn td.ft-count {
        font-size: 12px;
    }

    .table-booking-list .table-booking-inn td.ft-count {
        width: auto;
    }

    .table-booking-list .table-booking-inn td.hozara-dous {
        width: auto;
    }

    td.class-select b {
        font-size: 16px;
    }

    td.class-select {
        padding: 6px !important;
    }

    .tr-new-table .table-booking-inn tr td {
        font-size: 11px;
    }

    .table-booking-list .confirm-booking-table td.total-amount b {
        font-size: 14px;
    }

    .cro-dive ul.cro-dive-box-view-date li b {
        font-size: 10px;
        margin-left: 4px;
    }

    .cro-dive ul.cro-dive-box-view-date li {
        font-size: 11px;
        padding: 0 8px;
    }

    .documents ul a {
        padding: 8px 12px;
    }

    .vgm-div-box-left h2 {
        font-size: 14px;
    }

    /*.vgm-div-box-right a {*/
    /*    padding: 8px 24px;*/
    /*}*/
    .serach-box-booking {
        padding: 8px;
    }

    .booking-bl h3 b {
        font-size: 11px;
    }

    .booking-bl h3 {
        font-size: 10px;
    }

    .booking-bl {
        padding: 6px;
    }

    .booking-bl .booking-name ul li p {
        font-size: 9px;
        margin-right: 1px;
    }

    .booking-bl .booking-name ul li b {
        font-size: 8px;
        font-weight: 600;
    }

    .booking-bl b {
        font-size: 12px;
        font-weight: 500;
    }

    .booking-bl span {
        font-size: 8px;
    }

    .cro-dive.shipping-bill .vgm-div-box-right button {
        padding: 10px 28px;
    }

    td.goods_description-one {
        padding: 0 5px 0 0 !important;
    }

    td.goods_description {
        padding: 0 !important;
    }

    .booing-status ul li b.issued-color {
        font-size: 12px;
    }

    .booing-status ul li b {
        font-size: 12px;
    }

    .booing-status ul li p {
        font-size: 12px;
    }

    .bl-btn ul li button {
        padding: 6px 10px;
        font-size: 11px;
    }

    .bl-btn ul li button {
        margin: 4px 3px;
    }

    .search-input-file-cng input {
        width: 88%;
    }

    .container-fluid.co-p {
        margin: 29px auto 0;
    }

    .box-count ul li {
        padding: 4px 4px;
    }

    .box-count ul li h5 {
        font-size: 13px;
    }

    .option-form {
        margin-top: 20px;
    }

    .box-count .dry-reefer-select ul {
        margin: 4px auto 0;
    }

    .box-count li .input-group p {
        font-size: 16px;
    }

    .booking-highlight p {
        font-size: 9px;
    }

    .table-div table th {
        font-size: 10px;
    }

    .form-modelnew h5 {
        line-height: 16px;
        font-size: 12px;
    }

    .form-modelnew {
        margin-bottom: 12px;
    }

    .form-modelnew-inn {
        margin-bottom: 12px;
    }

    .form-modelnew-inn label {
        margin-bottom: 5px;
    }

    .div-popup-all {
        min-height: 415px;
        height: 415px;
    }

    .pop-up-loginnew h5 {
        font-size: 14px;
    }

    .total-charges-newconfirm-box li:nth-child(2) {
        padding: 14px 7px 0 7px;
        font-size: 10px;
    }

    .total-charges-newconfirm-box b {
        font-size: 17px;
    }

    .total-charges-newconfirm-box b span {
        font-size: 10px;
    }

    .confirm-booking-new-table table th:nth-child(1) {
        width: 54%;
    }

    .new-class-tr-div-packing-group .container-lable {
        padding-left: 25px;
        font-size: 12px;
    }

    .btn-confirm-new ul li button {
        padding: 12px 30px;
        font-size: 14px;
    }

    .view-price-breakdown-newconfirm-box button {
        padding: 6px 17px;
    }

    .search-full li:nth-child(1) {
        width: 13%;
    }

    .search-full li {
        padding: 0 3px;
    }

    .search-full li ul.ul-in li {
        padding: 0 6px;
    }

    .search span.span-select {
        font-size: 12px;
    }

    .modal-dialog.price-breackdown-popup {
        max-width: 79%;
    }

    .grow-booking h4 {
        font-size: 18px;
    }

    .grow-booking button {
        font-size: 12px;
    }

    .enquiry-form-page label {
        font-size: 12px;
    }

    .error-t-negative.cargo-weight.form-group {
        width: 84%;
    }

    .error-t-negative.cargo-weight-value {
        width: 16%;
    }

    .confirm-booking-table td {
        padding: 3px !important;
    }

    .view-price-breakdown-newconfirm-box span {
        font-size: 9px;
    }

    .upload-2-files .btn {
        padding: 8px 14px;
    }
    .total-charges-newconfirm-box b span {
        font-size: 13px;
    }
    .total-charges-newconfirm-box b {
        font-size: 17px;
    }
    .history-nev ul li a {
        padding: 9px 15px;
    }
}

@media (min-width: 1440px) and (max-width: 1600px) {
    .table-div table th {
        font-size: 13px;
    }

    .form-modelnew h5 {
        line-height: 25px;
        font-size: 15px;
    }

    .form-modelnew {
        margin-bottom: 12px;
    }

    .form-modelnew-inn {
        margin-bottom: 12px;
    }

    .form-modelnew-inn label {
        margin-bottom: 5px;
    }

    .div-popup-all {
        min-height: 418px;
        height: 418px;
    }

    .pop-up-loginnew h5 {
        font-size: 15px;
    }

    .modal-dialog.price-breackdown-popup {
        max-width: 66%;
    }
}